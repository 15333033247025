<template>
  <div class="map-style">
    <div class="map-title">
      <!-- <img src="../../assets/image/title.png" alt="" /> -->
      <span>全国案件判例</span>
      <span>不完全统计</span>
    </div>

    <div class="map-content">
      <div id="main"></div>
      <div id="count-style"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: ["nationalCases"],
  watch: {
    nationalCases: {
      handler(newVal) {
        //console.log("中国地图----我是子组件的watch监听");
        this.nationalCases = newVal;
        //console.log(this.nationalCases);
      },
      // 开启深度侦听
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      proBgColor: "#1E1E59",
      isShowText: false,
      caseData: [],
      timer: null,
      mCharts: null,
      myChart1: null,

    };
  },

  methods: {
    chartfn() {
      // 异步加载数据并渲染地图
      this.loadDataAndRenderMap();
    },

    async loadDataAndRenderMap() {
      const chinajson = require("../../assets/json/map/china.json");
      const { features } = chinajson;

      // 加载数据
      const cityArr = features.map((item) => ({
        adcode: item.properties.adcode,
        name: item.properties.name,
        value: item.properties.center,
      }));

      // 如果有疫情数据，则匹配每个省份的数据
      const caseArr = this.nationalCases ? this.matchCaseData(cityArr) : [];

      // 处理有案例数据的省份
      const airData = [];
         
      const sandianData = [];
      caseArr.forEach((item) => {
        if (item.totalQuantity !== 0) {
          airData.push({
            name: item.name,
            value: item.totalQuantity,
          });
          sandianData.push({
            name: item.name,
            value: item.value,
          });
        }
      });

     var scatterData = sandianData;
      console.log(scatterData);
      // 冒泡排序，截取前10条数据放入地图右侧的图表中
      caseArr.sort((a, b) => b.totalQuantity - a.totalQuantity);
      this.caseData = caseArr.slice(0, 10);

      // 渲染地图
      // const option = this.generateMapOption(airData, scatterData);
      echarts.registerMap("chinaMap", chinajson);
      this.mCharts = echarts.init(document.getElementById("main"));
      this.clickfn(this.mCharts, chinajson);
      let option = {
        // 鼠标进入之后显示省份以及数据
        tooltip: {
          show: true,
          confine: false,
          backgroundColor: "#cde6c7",
          formatter: (obj) => {
            const caseIndex = airData.findIndex(
              (item) => item.name === obj.name
            );
            if (obj.name === "" || caseIndex === -1) return;
            return [airData[caseIndex].value, obj.name].join("<br>");
          },
        },
        geo: {
          type: "map",
          label: {
            show: false,
            fontSize: 0,
          },
          map: "chinaMap",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          itemStyle: {
            areaColor: "#015479",
            borderColor: "#01cce8",
            borderWidth: 2,
            shadowColor: "rgba(0, 0, 0, 0.2)",
            shadowBlur: 8,
            opacity: 0.8,
            emphasis: {
              areaColor: "#56ceae",
            },
          },
        },
        series: [
          {
            data: airData,
            geoIndex: 0,
            type: "map",
          },
          {
            name: '散点',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: scatterData.map(item => ({
              name: item.name,
              value: item.value.concat(10) // 设置散点大小，可以根据需要调整
            })),
            symbolSize: 10, // 散点大小
            label: {
              show: false,
              // formatter: '{b}',
              // position: 'right'
            },
            emphasis: {
              label: {
                show: false
              }
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 4,
              backgroundColor: "#fff",
              color: '#ffffff' // 散点颜色
            }
          }
        ],
        visualMap: {
          show: false,
          inRange: {
            color: ["#018bb6"],
          },
        },
      };
      this.mCharts.setOption(option);
    },

    matchCaseData(cityArr) {
      const caseArr = [];
      cityArr.forEach((item) => {
        const matchingCase = this.nationalCases.find(
          (narItem) => narItem.adcode === item.adcode
        );
        if (matchingCase) {
          caseArr.push({
            name: item.name,
            totalQuantity: matchingCase.totalQuantity,
            value: item.value,
          });
        }
      });
      return caseArr;
    },

    // chartfn() {
    //   // 各个省份根据案例数据的不同颜色也不同
    //   var airData = [];
    //   // 散点坐标的数据
    //   var scatterData = [];
    //   var sandianData = [];
    //   // 鼠标移入时各个省份的案例数据
    //   const caseArr = [];
    //   let chinajson = require("../../assets/json/map/china.json");
    //   echarts.registerMap("chinaMap", chinajson);
    //   this.mCharts = echarts.init(document.getElementById("main"));
    //   this.clickfn(this.mCharts, chinajson);
    //   var cityArr = [];
    //   // 从chinajson文件中遍历出我们需要的数据
    //   if(chinajson.features){

    //   }
    //   chinajson.features.map((item) => {
    //     return cityArr.push({
    //       adcode: item.properties.adcode,
    //       name: item.properties.name,
    //       value: item.properties.center,
    //     });
    //   });

    //   // 根据后端返回的区号遍历出每个省份的具体数据
    //   if (this.nationalCases !== null) {
    //     cityArr.map((item) => {
    //       this.nationalCases.map((narItem) => {
    //         if (narItem.adcode == item.adcode) {
    //           return caseArr.push({
    //             // adcode: item.adcode,
    //             name: item.name,
    //             totalQuantity: narItem.totalQuantity,
    //             value: item.value,
    //           });
    //         }
    //       });
    //     });
    //   }

    //   // 有案例数据的省份颜色与其他省份进行区分
    //   caseArr.map((item) => {
    //     if (item.totalQuantity !== 0) {
    //       airData.push({
    //         name: item.name,
    //         value: item.totalQuantity,
    //       });
    //       sandianData.push({
    //         name: item.name,
    //         value: item.value,
    //       });

    //       return;
    //     }
    //   });
    //   scatterData = sandianData;
    //   // //console.log(sandianData);
    //   // 冒泡排序,截取前面的10条数据放入地图右侧的图表中
    //   for (var i = 0; i < caseArr.length - 1; i++) {
    //     for (var j = 0; j < caseArr.length - 1 - i; j++) {
    //       if (caseArr[j].totalQuantity < caseArr[j + 1].totalQuantity) {
    //         var temp = caseArr[j];
    //         caseArr[j] = caseArr[j + 1];
    //         caseArr[j + 1] = temp;
    //       }
    //     }
    //   }
    //   //console.log("看看我是否进行了排序");
    //   //console.log(caseArr);
    //   this.caseData = caseArr.slice(0, 10);

    //   let option = {
    //     // 鼠标进入之后显示省份以及数据
    //     tooltip: {
    //       show: true,
    //       confine: false,
    //       backgroundColor: "#cde6c7",
    //       // 可以自己定义鼠标进入之后显示的数据
    //       formatter: (obj) => {
    //         var caseIndex = 0;
    //         caseArr.map((item, index) => {
    //           if (item.name == obj.name) {
    //             return (caseIndex = index);
    //             // caseTotalQuantity=item.totalQuantity
    //           }
    //         });
    //         if (obj.name === "" || caseArr[caseIndex].name !== obj.name) {
    //           return;
    //         } else {
    //           // 显示各个省份的数据以及省名
    //           return [caseArr[caseIndex].totalQuantity, obj.name].join("<br>");
    //         }
    //       },
    //     },
    //     geo: {
    //       type: "map",
    //       label: {
    //         show: false, //是否展示标签
    //         fontSize: 0, //控制标签文本大小( 当 fontSize: 0 时不显示)
    //       },
    //       map: "chinaMap",
    //       left: 0,
    //       top: 0,
    //       right: 0,
    //       bottom: 0,
    //       // roam: true, //支持放大缩小
    //       // scaleLimit: {
    //       //   //滚轮缩放的极限控制
    //       //   min: 1, //缩放最小大小
    //       //   max: 5, //缩放最大大小
    //       // },
    //       // 设置地图背景色
    //       itemStyle: {
    //         areaColor: "#015479",
    //         borderColor: "#01cce8",
    //         borderWidth: 2,
    //         shadowColor: "rgba(0, 0, 0, 0.2)",
    //         shadowBlur: 8,
    //         opacity: 0.8,
    //         emphasis: {
    //           areaColor: "#56ceae", //鼠标滑过区域颜色
    //         },
    //       },
    //     },
    //     series: [
    //       {
    //         // 按照数据项分配调色盘中的颜色，每个数据项都可以使用不同的颜色。
    //         data: airData,
    //         geoIndex: 0,
    //         type: "map",
    //       },
    //       {
    //         data: scatterData, //配置散点的坐标数据
    //         type: "effectScatter",
    //         label: {
    //           show: false,
    //         },

    //         tooltip: {
    //           show: false,
    //         },
    //         coordinateSystem: "geo", //指明散点使用的坐标系统 geo坐标系统
    //         // colorBy: "series",?
    //         itemStyle: {
    //           color: "#fffff",
    //           borderColor: "#fff",
    //           borderWidth: 4,
    //           backgroundColor: "#fff",
    //         },
    //         symbolSize: 3,
    //         symbol: "circle",
    //         rippleEffect: {
    //           color: "#fff",
    //           scale: 6, //设置涟漪动画的缩放比例
    //           brushType: "fill", //stroke(涟漪)和fill(扩散)，两种效果
    //         },
    //       },
    //       { effectScatter: { legendHoverLink: true } },
    //     ],

    //     // 特殊区域颜色
    //     visualMap: {
    //       show: false,
    //       // min: 0,
    //       // max: 200,
    //       inRange: {
    //         color: ["#018bb6"],
    //       },
    //       // calculabel: true,
    //     },
    //   };
    //   this.mCharts.setOption(option);
    //   // mCharts.on("mouseover", function (params) {
    //   //   if (params != undefined) {
    //   //     mCharts.dispatchAction({
    //   //       type: "downplay",
    //   //     });
    //   //   }
    //   // });

    //   // 监听浏览器变化,图片对象调用缩放resize函数
    //   // window.addEventListener("resize", function () {
    //   //   this.mCharts.resize();
    //   // });
    // },
    //地图点击
    clickfn(mCharts, chinajson) {
      mCharts.on("click", (e) => {
        chinajson.features.map((item) => {
          if (e.name === item.properties.name && e.name !== "") {
            this.$router.push({
              path: "/help/National-involvment",
              query: { province: item.properties.adcode },
            });
          }
        });
      });
    },

    chartfn2() {
      //console.log(this.caseData);
      const chartDom = document.getElementById("count-style");
      this.myChart1 = echarts.init(chartDom);
      const cityName = [];
      const cityData = [];

      this.caseData.sort(function (a, b) {
        return b.totalQuantity - a.totalQuantity;
      });
      console.log(this.caseData);
      this.caseData.map((item) => {
        return cityName.push(item.name);
      });

      this.caseData.map((item) => {
        return cityData.push(item.totalQuantity);
      });
      cityData.sort(function (a, b) {
        return a - b;
      });
      let option;
      option = {
        title: {
          text: "数据统计",
          textStyle: {
            color: "#fff",
            fontWeight: 400,
            fontSize: "18px",
            textAlign: "center",
            // lineHeight: "22px",
            width: "100%",
            height: "100px",
          },

          textAlign: "left",
          padding: [20, 0],
          top: "-1%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "0%",
          right: "10%",
          bottom: "6%",
          top: "8%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          // boundaryGap: [0, 0.01],
          show: false,
        },
        yAxis: {
          type: "category",
          data: cityName,
          // nameTextStyle: {
          //

          // },
          axisLabel: {
            color: "#fff",
            fontWeight: 300,
            fontSize: "14px",
            //lineHeight: "20px",
            interval: 0,
            align: "right",
            margin: 10,
            width: 300,
          },
          axisTick: {
            show: false,
          },

          // 去掉y轴分割线
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            type: "bar",
            data: cityData,
            showBackground: true,
            barWidth: "20%",
            backgroundStyle: {
              color: "#091121",
            },
            itemStyle: {
              color: {
                type: "linear-gradient",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,

                colorStops: [
                  {
                    offset: 1,
                    color: "#05ABFA", // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: "#05ABFA",
                  },
                  {
                    offset: 0,
                    color: "#05ABFA", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      };
      option && this.myChart1.setOption(option);
      // 监听浏览器变化,图片对象调用缩放resize函数
      // window.addEventListener("resize", function () {
      //   this.myChart1.resize();
      // });
    },
    disposeChart() {
      if (this.myChart) {
        this.myChart.destroy();
        this.myChart = null;
      }
    },
  },
  created() {},
  mounted() {
    this.timer = setTimeout(() => {
      this.chartfn();
      this.chartfn2();
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    clearTimeout(this.timer);
    // 销毁 echarts 实例，释放资源
    this.disposeChart();
    //   if (this.myChart) {
    //   this.myChart.dispose();
    // }
    // if (this.myChart1) {
    //   this.myChart1.dispose();
    // }
  },
};
</script>
<style lang="less">
.map-style {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .map-title {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    height: 7%;

    span:nth-child(1) {
      color: var(--web-color-text-primary, #f2f4f6);
      font-family: "PingFang SC";
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      // height:200px
    }

    span:nth-child(2) {
      color: var(--web-color-text-secondary, #87909f);
      text-align: right;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-right: 50px;
    }
  }

  .map-content {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding-left: 48px;
    padding-right: 38px;
  }

  #count-style {
    width: 30%;
    height: 100%;
    box-sizing: border-box;
    color: #fff;
    // padding-top: 10px;
    margin-left: 48px;
  }
  .dataStatistics {
    width: 100%;
    height: 30px;
    font-size: 16px;
    font-weight: 400;
    color: #6ff0ea;
    line-height: 22px;
  }
  .count-style-item {
    width: 100%;
    display: flex;
    line-height: 20px;
    margin-top: 5px;
    height: 28px;
    .el-progress .el-progress-bar .el-progress-bar__outer {
      border-radius: 0px;
    }
    .el-progress-bar__inner {
      background-image: linear-gradient(
        90deg,
        #6ff0ea 0%,
        #a2d4ae 86%,
        #edf16e 100%
      );
      border-radius: 0px;
    }
    .city {
      min-width: 30px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      margin-right: 6px;
    }
  }
}

#main {
  // width: 70%;
  // margin-left: 40px;
  width: 80%;
  // height: 100%;
  margin-top: 10px;

  // background-color: #6ff0ea;
  // @media screen and (min-width: 768px) and (max-width: 1872px) {
  //   width: 70%;
  //   height: 100%;
  // }
  // @media screen and (min-width: 1830px) and (max-width: 2630px) {
  //   width: 70%;
  //   height: 100%;
  // }
}

.el-progress {
  width: 120px;
  height: 5px;
  margin-top: 8px;
}
</style>
