import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
import VueAxios from "vue-axios";
// import "vis/dist/vis.css"
import "../src/assets/css/reset.less";
import scroll from "vue-seamless-scroll";
import { displaynotify } from "@/utils/displaynote";
import VueClipboards from "vue-clipboard2";
import elTableInfiniteScroll from "el-table-infinite-scroll";
import global from "@/components/Global.vue";
import VueContextMenu from "vue-contextmenu";
import tool from "@/utils/tool.js";
import tronfn from "@/utils/tron.js";
import { Buffer } from "buffer-es6";
// 首页引导插件
import intro from "intro.js"; // introjs库
import "intro.js/introjs.css"; // introjs默认css样式
// introjs还提供了多种主题，可以通过以下方式引入
import "intro.js/themes/introjs-modern.css"; // introjs主题

// 把intro.js加入到vue的prototype中，方便使用，就可以直接通过this.$intro()来调用了
Vue.prototype.$intro = intro;

Vue.prototype.tool = tool;
Vue.prototype.tronfn = tronfn;

Vue.use(ElementUI);
Vue.prototype.$showDialog = function (message, onConfirm, onCancel) {
  this.$confirm(message, "提示", {
    confirmButtonText: "退出",
    cancelButtonText: "保存画布",
    // cancelButtonClass: 'custom-cancel-button',
    cancelButtonClass: "custom-cancel-button",
    confirmButtonClass: "custom-confirm-button",
    distinguishCancelAndClose: true,
    customClass: "confirm-p",
    // closeOnClickModal: false, // 防止点击模态框背景关闭对话框
    // closeOnPressEscape: false,
    beforeClose: (action, instance, done) => {
      if (action === "confirm") {
        // 用户点击了确定按钮的处理逻辑
        // console.log('用户点击了确定按钮');
        done(); // 关闭对话框
      } else if (action === "cancel") {
        // 用户点击了取消按钮的处理逻辑
        localStorage.canvastemporarily = true;
        // console.log('用户点击了取消按钮');
        done(); // 关闭对话框
      } else {
        // 用户点击了关闭按钮的处理逻辑
        // console.log('用户点击了关闭按钮');
        done(); // 关闭对话框
      }
    },
  })

    .then(() => {
      // let bread = document.querySelector("#bread");
      // bread.style.display = "block";
      onConfirm();
    })
    .catch(() => {
      onCancel();
    });
  // 这里可以使用你喜欢的弹窗库或原生 JavaScript 创建弹窗
  // if (window.confirm(message)) {
  //   onConfirm();
  // } else {
  //   onCancel();
  // }
};

//
Vue.use(VueContextMenu);

Vue.use(elTableInfiniteScroll);
Vue.use(VueClipboards);
// import "./utils/rem";
Vue.prototype.displaynotify = displaynotify;
Vue.prototype.GLOBAL = global;
// import { Buffer } from "buffer-es6";
// global.Buffer = Buffer;

// global.getConfig();
// Vue.prototype.displaynotify = displaynotify
// Vue.prototype.$shortcuts = new Shortcuts();
// import './utils/dayjs'

// Vue.prototype.dayjs = dayjs;//可以全局使用dayjs
import moment from "moment";
// Vue.use(axios);
// Vue.use(scroll);
Vue.prototype.$moment = moment;
// Vue.use(axios);
Vue.use(scroll);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
// router.beforeEach((to, from, next) => {
/* 路由发生变化修改页面title */
// var newHostname = window.location.hostname;
// if (
//   newHostname == "dashboard.tanlian.online" ||
//   newHostname == "api.tanlian.online"
// ) {
//   if (to.meta.title) {
//     document.title = "Tanlian-探链-" + to.meta.title;
//   }
// } else if (newHostname == "dashboard.graphchain.top") {
//   if (to.meta.title) {
//     document.title = "Graphchain-探链-" + to.meta.title;
//   }
// } else {
//   if (to.meta.title) {
//      document.title = "Tanlian-探链-" + to.meta.title;
//   }
// }
// next();
// });
