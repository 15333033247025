<template>
  <div style="width: 100%" class="binding-guge codegoogle">
    <div class="textConAny">
      <!-- <p class="bind-p1">如何使用谷歌验证码</p> -->
      <!-- <p class="bind-p2">Google 身份验证器绑定方式</p> -->
      <p class="bind-p3">1、微信小程序</p>
      <div
        class="b-binding"
        style="display: flex; justify-content: space-between"
      >
        <div class="binding-min code-min">
          <div class="binding-img" style="display: flex">
            <img
              class="cx-img"
              src="@/assets/image/login/google1.png"
              alt="小程序图片"
            />
            <img src="@/assets/image/login/google2.png" alt="小程序图片" />
          </div>
          <p class="step">
            第一步：在微信搜索“腾讯身份验证器”或者“宁盾令牌”进入小程序
          </p>
        </div>

        <div class="binding-min1 code-min1">
          <img src="@/assets/image/login/google3.png" alt="小程序图片" />
          <p class="step">
            第二步：请将小程序你绑定的探链中动态数字输入验证码输入框
          </p>
        </div>
      </div>
      <p class="bind-p3" style="margin-top: 30px">
        2、手机宁盾令牌（iOS和Android手机用户）或者google
        authenticator（仅限iOS手机用户）
      </p>
      <div
        class="b-binding"
        style="display: flex; justify-content: space-between"
      >
        <div class="binding-min code-min">
          <div class="binding-img" style="display: flex">
            <img
              class="cx-img"
              src="@/assets/image/login/google4.png"
              alt="小程序图片"
            />
            <img src="@/assets/image/login/google5.png" alt="小程序图片" />
          </div>
          <p class="step">
            第一步：在App Store或者安卓应用市场搜索对应app然后下载
          </p>
        </div>

        <div class="binding-min1 code-min1">
          <img src="@/assets/image/login/google6.png" alt="小程序图片" />
          <p class="step">第二步：请将 App中你绑定的动态数字输入验证码输入框</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["googleform", "handleClose3"],
};
</script>
<style lang="less" scoped>
@import "@/assets/css/newlogin.less";
.codegoogle {
  padding-left: 20px;
  .binding-min1 {
    margin-left: 80px;
  }
  .code-min {
    width: 48% !important;
  }
  .code-min1 {
    width: 50% !important;
  }
}
.forgetpassword-style {
  cursor: pointer;
}
</style>
